import React from 'react'
import { Link } from 'gatsby'
import './styles.scss'


export const ProductColumnCard = ({ url, img, name, alt_text, description, relatedProduct }) => {
  return (
    <div className="column-card text-left mb-5">
      <Link to={`${url}/`}>
        <img className="img-responsive" src={img} alt={alt_text || name} />
        <h2 className={relatedProduct ? "text-center" : "text-left non-relatedProduct"}>
          {name}
        </h2>
      </Link>
      {description &&
        <p className="mb-2">{description}</p>
      }
    </div>
  )
}

export default ProductColumnCard